app.factory('quote', ['$http','Config','$rootScope','CacheFactory', function($http,Config,$rootScope,CacheFactory) {

	var factoryFns =  {

	  get: function(data) {
	  	return $http({
		  method: 'POST',
		  data:data,
		  url: Config.api_url+'quote',
		  headers:{
		  	'authorization':Config.api_key
		  }
		});
	  },
	  validateApplyDiscount:function(data){
	  	return $http({
		  method: 'POST',
		  data:data,
		  url: Config.api_url+'quote/validate-apply-promo',
		  headers:{
		  	'authorization':Config.api_key
		  }
		});
	  },sendCustomerQuote: function(data){
	  	query ='?web-quote-id='+data.webQuoteID+'&email='+data.email;
	  	return $http({
		  method: 'GET',
		  url: Config.api_url+'quote/send-customer-quote'+query,
		  headers:{
		  	'authorization':Config.api_key
		  }
		});
	  },getCountryList:function(){
	  	return $http({
		  method: 'GET',
		  url: Config.api_url+'doris/countries',
		  headers:{
		  	'authorization':Config.api_key
		  }
		});
	  },getCraftyResults:function(postcode){
	  	return $http({
		  method: 'POST',
		  data:{
		  		key:Config.craftyclicks_api_key,
		  		query:postcode,
		  		country:'GB'
		  },
		  url: 'https://api.craftyclicks.co.uk/address/1.1/find',
		  headers:{
		  	'authorization':Config.api_key
		  }
		});
	  },selectCraftyAddress:function(id){
	  	return $http({
		  method: 'POST',
		  data:{
		  		key:Config.craftyclicks_api_key,
		  		id:id,
		  		country:'GB'
		  },
		  url: 'https://api.craftyclicks.co.uk/address/1.1/retrieve',
		  headers:{
		  	'authorization':Config.api_key
		  }
		});
	  },
	  getSurcharges: function(data){
        query = '?brand='+data.brand+
            '&delivery-postcode='+data.deliveryPostcode+
            '&collection-postcode='+data.collectionPostcode;
        return $http({
        method: 'GET',
        url: Config.api_url+'surcharges'+query,
        headers:{
          'authorization':Config.api_key
        }
        });
      }

	}

	return factoryFns;

}]);