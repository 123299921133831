app.factory('setting', ['$http','Config', function($http,Config) {

	var factoryFns =  {

	  get: function() {
	  	return $http({
		  method: 'GET',
		  url: Config.api_url+'setting?brand='+Config.brand_key,
		  headers:{
		  	'authorization':Config.api_key
		  }
		});
	  },
	  getTime: function() {
		return $http({
			method: 'GET',
			url: Config.api_url+'time',
			headers:{
				'authorization':Config.api_key
			}
		  });
	  }

	}

	return factoryFns;

}]);