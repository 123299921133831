app.factory('helper',function(){

	return {
	  hideLoader: function() {
	  	$('.ajax_loader').fadeOut();
	  },
	  showLoader: function() {
	  	$('.ajax_loader').show();
	  },formatMoney: function(num){
	      return accounting.formatMoney(num,{ symbol: "GBP",  format: "%v",decimal:'.', precision:2,thousand:''});
	  },timedHackCalc: function(transport,surcharges){
		  	//check if time slot lie in the red zone and double the price
		  	delTimeStart = parseInt(transport.delivery.timeStart.split(":")[0]);
		  	colTimeStart = parseInt(transport.collection.timeStart.split(":")[0]);

		  	//get delivery & collection surcharge price
		    if(transport.delivery.option){
		    	var delSurchargePrice = 0;
			    angular.forEach(surcharges.delivery_surcharges, function(surcharge, key) {
			        if(surcharge.carriage_surcharge_id == transport.delivery.option.carriage_surcharge_id){
						delSurchargePrice = surcharge.price;
			        }
			    });
		    	if(delTimeStart<5 || delTimeStart>20){
		    		transport.delivery.option.price = 2*delSurchargePrice;
		    	}else{
		    		transport.delivery.option.price = delSurchargePrice;
		    	}
		    }

		    if(transport.collection.option){
		    	var colSurchargePrice = 0;
		    	angular.forEach(surcharges.collection_surcharges, function(surcharge, key) {
			        if(surcharge.carriage_surcharge_id == transport.collection.option.carriage_surcharge_id){
						colSurchargePrice = surcharge.price;
			        }
			    });
		    	if(colTimeStart<5 || colTimeStart>20){
		    		transport.collection.option.price = 2*colSurchargePrice;
		    	}else{
		    		transport.collection.option.price = colSurchargePrice;
		    	}
		    }
		  	return transport;
		  },hireDays: function(deliveryDate,collectionDate){
			  //Get 1 day in milliseconds
			  var one_day=1000*60*60*24;
			  // Convert both dates to milliseconds
			  var date1_ms = deliveryDate.getTime();
			  var date2_ms = collectionDate.getTime();
			  // Calculate the difference in milliseconds
			  var difference_ms = date2_ms - date1_ms;
			  // Convert back to days check setting and return
			  return Math.round(difference_ms/one_day)+1; 
		  }
	};

});