app = angular.module('manchesterFurnitureHire', [
                                      'ngMessages',
                                      'ngSanitize',
                                      'ngAnimate',
                                      'ui.bootstrap',
                                      'toaster',
                                      'angular-cache',
                                      'ngSentry'
                                      ]);

app.value('Config',{
	base_url: angular.element('#base_url').val()+'/',
	api_url: angular.element('#api_url').val(),
	brand_key: angular.element('#brand_key').val(),
  api_key: angular.element('#api_key').val(),
  vatRate:angular.element('#vat_rate').val(),
  craftyclicks_api_key:"8be91-6c930-ca0b3-06bec",
  insideWorkingHours:[8,9,10,11,12,13,14,15,16],
  outsideWorkingHours:[0,1,2,3,4,5,6,7,17,18,19,20,21,22]
});

app.config(function (CacheFactoryProvider) { 
    angular.extend(CacheFactoryProvider.defaults, { 
    maxAge: 4 * 60 * 60 * 1000, // 4 hour
    deleteOnExpire: 'aggressive',
    storageMode:'localStorage'
    });
});


app.run(['CacheFactory','Config','$rootScope', function(CacheFactory,Config,$rootScope) {

  if (!angular.isDefined(CacheFactory.get('appCache'))) {
      CacheFactory.createCache('appCache');
  }

  if(!angular.isDefined(CacheFactory.get('appCache').get('hireDays'))){
      CacheFactory.get('appCache').put('hireDays',8);
  }

  $rootScope.load = false;

}]);