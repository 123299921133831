app.controller('TopCartController', ['$scope','Config','cart','toaster','$window', function($scope,Config,cart,toaster,$window){

	$scope.cart = cart.getCart();
	$scope.base_url = Config.base_url;

	$scope.remove = function(idx){
		var product_name = $scope.cart.content[idx].data.product_name;
		cart.remove(idx);
		toaster.pop('success', "Quote Updated!", product_name+' removed successfully from quote!');
	}

}]);