app.factory('cart', ['$http','Config','$rootScope','CacheFactory', function($http,Config,$rootScope,CacheFactory) {


	if(angular.isDefined(CacheFactory.get('appCache').get('cart'))){
		var cart = {
			content:CacheFactory.get('appCache').get('cart').content,
			totals:CacheFactory.get('appCache').get('cart').totals,
			hireSaving:CacheFactory.get('appCache').get('cart').hireSaving,
			cartChanged:CacheFactory.get('appCache').get('cart').cartChanged
		}
	}else{
		var cart = {
			content:[],
			totals:{},
			hireSaving:{},
			cartChanged:false,
		}
	}

	var factoryFns =  {
	  validQty: true,
	  add: function(product,qty) {

	  	//check if object exist in cart the trigger an update instead
	  	var exist = false;
	  	
	  	angular.forEach(cart.content, function(item, key) {
		  if(item.data.product_id === product.product_id){
	  			exist = true;
	  			cart.content[key].qty = parseInt(cart.content[key].qty) + parseInt(qty);
	  		}
		});

	  	if(!exist){
	    	cart.content.push({
	    						id:product.product_id,
	    						data:product,
	    						qty:qty,
	    						price:product.price,
	    						subtotal:qty*product.price
	    					});
	  	}

	  	product.qty = 1;

	  	factoryFns.updateCart();
	  },
	  getCart: function(){
	  	return cart;
	  },
	  remove: function(idx){
	  	cart.content.splice(idx,1);
	  	factoryFns.updateCart();
	  },
	  updateTotals: function(){
	  	cart.totals.productTotal = 0;
	  	angular.forEach(cart.content, function(item, key) {
	  		//item.price = factoryFns.priceBreaksCheck(item.data,item.qty);
	  		if(!angular.isDefined(CacheFactory.get('appCache').get('hireWeeks'))){
	  			item.subtotal = item.qty*item.price;
	  		}else{
	  			item.subtotal = item.qty*item.price*CacheFactory.get('appCache').get('hireWeeks');
	  		}

	  		cart.totals.productTotal += item.subtotal;
	  	});
	  	cart.totals.productTotal = parseFloat(cart.totals.productTotal.toFixed(2));
	  	factoryFns.getHireSaving();
	  },
	  qtyChange:function(idx,qty){
	  	factoryFns.validQty = factoryFns.qtyValidation(qty);
	  	if(factoryFns.validQty){
	  		cart.content[idx].qty = qty;
	  		factoryFns.updateCart();
	  	}
	  },
	  updateCache: function(){
	  	CacheFactory.get('appCache').put('cart',cart);
	  	$rootScope.load = false;
	  },
	  qtyValidation: function(qty){
	  	if(Math.floor(qty) == qty && $.isNumeric(qty) && qty>0 ){
	  		return true;
	  	}else{
	  		return false;
	  	}
	  },
	  incQty: function(idx){
	  	//get item from the cart and increment the quantity
		cart.content[idx].qty++;
		factoryFns.updateCart();
	  },decQty: function(idx){
	  	//check if the quantity is not less than 1 then decrease the qty
	  	if(cart.content[idx].qty>1){
	  		cart.content[idx].qty--;
			factoryFns.updateCart();
	  	}
	  },updateCart: function(){
		cart.cartChanged = true;
	  	$rootScope.load = true;
	  	//create an array of cart products and their quantities
	  	var products = [];
	  	angular.forEach(cart.content, function(item, key) {
	  		products.push({
	  			id:item.data.product_id,
	  			qty:item.qty
	  		});
	  	});

	  	var data = {
	  				'products':products,
	  				'hire-days':(angular.isDefined(CacheFactory.get('appCache').get('hireDays'))) ? CacheFactory.get('appCache').get('hireDays'): 8,
	  				'brand':Config.brand_key
	  			}
				
		if(angular.isDefined(CacheFactory.get('appCache').get('deliveryDate'))){
			data.deliveryDate = moment(CacheFactory.get('appCache').get('deliveryDate')).format('YYYY-MM-DD');
		}

		if(angular.isDefined(CacheFactory.get('appCache').get('collectionDate'))){
			data.collectionDate = moment(CacheFactory.get('appCache').get('collectionDate')).format('YYYY-MM-DD');
		}
	  	//send an ajax call to get the new prices
	  	$http({
		  method: 'POST',
		  url: Config.api_url+'cart/update-prices',
		  data:data,
		  headers:{
		  	'authorization':Config.api_key
		  }
		}).then(function(response) {
		   	angular.forEach(cart.content, function(item, key) {
		   		//update the cart with new prices
		  		item.price = response.data[item.data.product_id];
		  	});
		   	//update totals
		  	factoryFns.updateTotals();
		  }, function(response) {
		    console.log(response);
		  });
	  },clear: function(){

			$http({
			  method: 'GET',
			  url: Config.base_url+'checkout/reset-token'
			}).then(function(response) {
				cart.content = [];
				CacheFactory.get('appCache').remove('cart');	
				CacheFactory.get('appCache').remove('quote');
				CacheFactory.get('appCache').remove('hireDays');
				CacheFactory.get('appCache').remove('hireWeeks');
				CacheFactory.get('appCache').remove('deliveryDate');
				CacheFactory.get('appCache').remove('collectionDate');
			  }, function(response) {
			    console.log(response);
			  });

	  },totalWeight: function(){
	  	var totalWeight = 0;
	  		angular.forEach(cart.content, function(item, key) {
		   		//update the cart with new prices
		  		totalWeight += item.data.weight*item.qty;
		  	});
		return totalWeight;
	  },getHireSaving:function(){
	  	$http({
		  method: 'GET',
		  url: Config.api_url+'quote/hire-saving?brand='+Config.brand_key+'&hire-days='+CacheFactory.get('appCache').get('hireDays')+'&product-total='+cart.totals.productTotal,
		  headers:{
		  	'authorization':Config.api_key
		  }
		}).then(function(response) {
			cart.hireSaving = {	
					value:response.data.value,
					percentage:response.data.percentage
				};
			factoryFns.updateCache();
		  }, function(response) {
		    console.log(response);
		  });
	  }
	};

	return factoryFns;

}]);