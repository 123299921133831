app.controller('TopHeaderController', ['$scope','$http','Config','helper','toaster', function($scope,$http,Config,helper,toaster){
	$scope.name = '';
	$scope.company = '';
	$scope.telephone = '';
	$scope.time = '';
	$scope.submitted = false;

	$scope.showCallbackPopup = function(){
		$('#callBackModal').modal('show');
	}

	$scope.requestCallbackSubmit = function(form){
		$scope.submitted = true;
		if(form.$valid){
			helper.showLoader();
			data = {
					'brand':Config.brand_key,
					'name':$scope.name,
					'telephone':$scope.telephone,
					'company':$scope.company,
					'time':$scope.time,
					'token': $('#user_token').val()
				};

			$http({
			  method: 'POST',
			  data:data,
			  url: Config.api_url+'callback/request',
			  headers:{
			  	'authorization':Config.api_key
			  }
			}).then(function(response) {
			   	form.$setPristine();
			   	$scope.name = '';
				$scope.company = '';
				$scope.telephone = '';
				$scope.time = '';
				$scope.submitted = false;
		        form.$setUntouched();
		        $('#callBackModal').modal('hide');
		        helper.hideLoader();
		        toaster.pop('success', "Callback Scheduled!", 'Agent '+response.data.agent+' will contact you in the requested time.',3000);
		        // ga('send', 'pageview', {page: '/callback.html'});
				gtag('event', 'page_view', {page_location: Config.base_url + 'callback.html'});
			  }, function(response) {
			    console.log(response);
			 });
		}
	}
}]);