app.controller('ContactController', ['$scope','$http','Config','$rootScope', function($scope,$http,Config,$rootScope){
	$scope.name = '';
	$scope.email = '';
	$scope.telephone = '';
	$scope.message = '';
	$scope.captchaAnswer = '';
	$scope.sent = false;

	if(!localStorage.firstNumber){
		localStorage.setItem("firstNumber", Math.floor((Math.random() * 10) + 1));
		localStorage.setItem("secondNumber",Math.floor((Math.random() * 10) + 1));
	}
	$scope.captcha={
		firstNumber:localStorage.getItem("firstNumber"),
		secondNumber:localStorage.getItem("secondNumber")
	}

	$scope.send = function(form){
		if(form.$valid){
			$rootScope.load = true;
			//send an ajax call to save the message and send an email notification
			data = {
					'name':form.name.$modelValue,
					'email':form.email.$modelValue,
					'telephone':form.telephone.$modelValue,
					'message':form.message.$modelValue,
					'ip':$('#user_ip').val(),
					'user-agent': $('#user_agent').val(),
					'token': $('#user_token').val(),
					'brand':Config.brand_key
				};

			$http({
			  method: 'POST',
			  data:data,
			  url: Config.api_url+'message',
			  headers:{
			  	'authorization':Config.api_key
			  }
			}).then(function(response) {
			   	$scope.sent = true;
			   	$rootScope.load = false;
				form.$setPristine();
				$scope.message = '';
				forn.$setUntouched();

			   	// ga('send', 'pageview', {page: '/contact.html'});
				gtag('event', 'page_view', {page_location: Config.base_url + 'contact.html'});
			  }, function(response) {
			    console.log(response);
			 });
		}
	}
}])